<template>
  <div>
    <LayoutHeader />
    <LayoutContainer>
      <div class="py-8">
        <slot />
      </div>
    </LayoutContainer>
    <LayoutFooter />
  </div>
</template>
